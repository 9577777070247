import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

interface Validate {
  error: string;
  message: string;
}

@Component({
  selector: 'app-form-control',
  standalone: true,
  imports: [CommonModule, FloatLabelModule, InputTextModule],
  templateUrl: './form-control.component.html',
  styleUrl: './form-control.component.scss'
})
export class FormControlComponent {
  @Input() control: any;
  @Input({ required: true }) label = '';
  @Input() required = false;
  @Input() floatLabel = false;
  @Input() validates: Validate[] = [];
}
